<template>
  <div class="BadgeTab container mt-4">
    <div class="AccountSettingsTab__Container ml-5 mb-6">
      <h2 class="mb-3">
        {{ $t('settings.card.tab') }}
      </h2>
      <div v-if="!isEmpty(getUserBadge)">
        <ui-text-input
          name="badgeType"
          :label="$t('settings.card.type')"
          :value="badgeProviderNameFormatted"
          class="d-block my-4"
          data-test-id="badge_type-input_text"
          data-cs-mask
          disabled
        />
        <ui-text-input
          name="badgeValue"
          :label="$t('settings.card.reference_number')"
          :value="getUserBadge.number"
          class="d-block my-4"
          data-test-id="badge_value-input_text"
          data-cs-mask
          disabled
        />
      </div>
      <div v-else>
        <EmptyStateComponent
          :src="emptyPaymentMethods"
          :title="$t('settings.card.no_badge')"
          :description="$t('settings.no_desktop')"
          has-background
        />
      </div>

      <FeedbackModalComponent
        v-model="successModal.isOpen"
        v-bind="successModal"
      />
    </div>
  </div>
</template>

<script>
import isEmpty from 'lodash/isEmpty';
import { mapMutations } from 'vuex';
// Components
import config from '@/config';
import EmptyStateComponent from '@/components/EmptyState/EmptyStateComponent';
import FeedbackModalComponent from '@Shared/components/FeedbackModal/FeedbackModalComponent';

// Constants
import { genericSuccessArgs } from '@/constants/defaultModalArgs';
import { emptyPaymentMethods } from '@/utils/publicImages';

// Store
import { hasRestrictiveBadge } from '@/stores/CSOperator/CSOperatorMapper';
import {
  MUTATIONS as KeyCardRoadblockMutations,
  NAMESPACE as KeyCardRoadblockNameSpace,
} from '@/domains/Document/Keycard/KeyCardModule';
import { fetchUserAccount, getUserBadge } from '@/stores/User/UserMapper';

import { useKeycardRoadblock } from '@/composable/User/documents/keycard';
import { fetchUserDetails, userUuid } from '@/stores/User/UserData/UserDataMapper';

import { GTM_MODAL_EVENTS } from '@/constants/gtm';
import { useTrackingGTM } from '@/composable/GTM/gtm';
import { useNotifications } from '@/composable/App/Notifications/useNotifications';
import { webAppColors } from '@/constants/themes';

export default {
  components: {
    EmptyStateComponent,
    FeedbackModalComponent,
  },
  setup() {
    const { showRoadblock: showKeycard } = useKeycardRoadblock();
    const { trackPageView, trackModalView } = useTrackingGTM();
    const { notifyError } = useNotifications();
    return {
      showKeycard,
      trackPageView,
      trackModalView,
      notifyError,
      webAppColors,
    };
  },
  data() {
    return {
      successModal: {
        isOpen: false,
      },
    };
  },
  computed: {
    getUserBadge,
    hasRestrictiveBadge,
    userUuid,

    badgeProviderNameFormatted() {
      return isEmpty(this.getUserBadge.badgeProviderName)
        ? ''
        : this.getUserBadge.badgeProviderName.replace(/_/g, ' ').replace(/-/g, ' ');
    },
  },
  async created() {
    this.emptyPaymentMethods = emptyPaymentMethods;
    this.keyCardMessageTypes = {
      linked: { key: 'complete_documentation.new_custom_badge_flow.link_custom_keycard.modal_success_message' },
      requested: { key: 'complete_documentation.new_custom_badge_flow.request_ubeeqo_keycard.modal_success_message', operator: config.data.appName },
      pickedUp: { key: 'complete_documentation.new_custom_badge_flow.pick_up_keycard.modal_success_message' },
    };
    this.successModal = {
      ...this.successModal,
      ...genericSuccessArgs(this.$t),
      primaryCallToActionColor: this.webAppColors.informative,
      primaryCallToAction: () => {
        this.successModal.isOpen = false;
        this.trackPageView();
      },
    };
  },
  methods: {
    isEmpty,
    fetchUserAccount,
    fetchUserDetails,

    ...mapMutations(KeyCardRoadblockNameSpace, {
      resetKeyCardRoadblockState: KeyCardRoadblockMutations.resetData,
    }),

    refreshData() {
      Promise.all([
        this.fetchUserDetails(),
        this.fetchUserAccount({
          userUuid: this.userUuid,
        }),
      ]).catch(error => {
        this.displayErrorModal(error.message);
      });
    },

    displaySuccessModal(message = this.keyCardMessageTypes.linked) {
      this.$fullScreen.close();
      this.resetKeyCardRoadblockState();
      this.refreshData();

      const title = this.$t(
        message.key,
        isEmpty(message.operator) ? null : { operator: message.operator },
      );

      this.successModal = {
        ...this.successModal,
        title,
        isOpen: true,
      };

      this.trackModalView({ modalName: GTM_MODAL_EVENTS.submitKeyCardConfiguration });
    },

    displayErrorModal(error) {
      this.$fullScreen.close();

      this.notifyError({
        text: error || 'Error!',
      });
    },

    openCompleteDocumentation() {
      this.showKeycard({
        onKeycardRequested: () => this.displaySuccessModal(this.keyCardMessageTypes.requested),
        onKeycardLinked: () => this.displaySuccessModal(this.keyCardMessageTypes.linked),
        onKeycardPickedUp: () => this.displaySuccessModal(this.keyCardMessageTypes.pickedUp),
        onError: this.displayErrorModal,
      });
    },
  },
};
</script>
